import * as React from "react";

const Footer = () => {
  return (
    <footer>
      <span>Copyright &copy; 2021 Mainly Mobiles Communications</span>
    </footer>
  );
};
export default Footer;
